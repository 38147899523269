import stickybits from 'stickybits'
import select from 'select-dom'

const init = async () => {
    const header = select.all('.comparison-table__header')
    if (header) {
		header.forEach(el => stickybits(el, { useStickyClasses: true, stickyBitStickyOffset: 50 }))
    }

    // Get all header buttons
    const headerButtons = select.all('a.comparison-table__header--item')

    headerButtons.forEach((el) => {
        el.addEventListener('click', e => {
            e.preventDefault()

            // Remove 'active' class from all buttons
            headerButtons.forEach(button => {
                button.classList.remove('active')
            })

            // Add 'active' class to clicked button
            el.classList.add('active')

            // Get the data-col attribute from clicked button
            const selectedCol = el.getAttribute('data-col')

            // Get all table body items
            const bodyItems = select.all('.comparison-table__body--item')

            // Remove active class from all body items first
            bodyItems.forEach(item => {
                item.classList.remove('active')
            })

            // Add active class to matching items
            const matchingItems = select.all(`.comparison-table__body--item[data-col="${selectedCol}"]`)
            matchingItems.forEach(item => {
                item.classList.add('active')
            })
        })
    })
}

export default {
    init,
}
